<template>
     <div class="container-box">
        <el-tabs v-model="Award_publicity_module" type="border-card" class="demo-tabs">
            <el-tab-pane label="信息通信工程实践" name="1">
                <el-table v-loading="loading" border :scrollbar-always-on="true" :data="EngineeringCompetitionData"
                            style="width: 100%;border-radius: 0.05rem;">
                            <el-table-column type="index" sortable label="序号" width="60" />
                            <el-table-column prop="school" sortable label="学校" width="120" />
                            <el-table-column prop="name1" sortable label="学生1" />
                            <el-table-column prop="name2" sortable label="学生2" />
                            <el-table-column sortable label="指导教师1">
                                <template #default="scope">
                                    <div>
                                        <el-input 
                                        v-model="scope.row.name3" 
                                        @change="handleInputReset(scope.row.member_id3,scope.row.name3,'省赛')" 
                                        size="small"
                                        />
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column sortable label="指导教师2">
                                <template #default="scope">
                                    <div>
                                        <el-input v-model="scope.row.name4" @change="handleInputReset(scope.row.member_id4,scope.row.name4,'省赛',true)" size="small"/>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="prize" sortable label="奖项" />
                        </el-table>
            </el-tab-pane>
            <el-tab-pane label="产教融合5G+创新应用设计赛" name="2">
                <el-table v-loading="Loading_5G" border :scrollbar-always-on="true"
                        :data="tableData_5G"
                        :row-key="record=>record.team_id"
                        @expand-change="handleExpendRow"
                        @selection-change="handleSelectionChange_5G"
                        style="width: 100%;border-radius: 0.05rem;">
                        <el-table-column type="index" sortable label="序号" width="60" />
                        <el-table-column type="expand" width="120">
                            <template #default="props">
                                <el-table :data="props.row.members">
                                    <el-table-column prop="role" label="角色" width="80">
                                        
                                    </el-table-column>
                                    <el-table-column label="姓名" width="120">
                                        <template #default="scope">
                                            <el-input 
                                            v-model="scope.row.name" 
                                            @change="handleInputReset(scope.row.member_id,scope.row.name,'省赛',true)" 
                                            v-if="scope.row.role==='指导教师'"
                                            >
                                            </el-input>
                                            <span v-else>{{ scope.row.name }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="id_num" label="身份证号"></el-table-column>
                                    <el-table-column prop="phone" label="手机号"></el-table-column>
                                </el-table>
                            </template>
                        </el-table-column>
                        <el-table-column prop="school" sortable label="学校全称"/>
                        <el-table-column prop="province" sortable label="学校所在省份"/>
                        <el-table-column prop="name" sortable label="队名" width="180" />
                    </el-table>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import { userInfo } from "api/apis.js";
import { ElMessage } from 'element-plus'
export default {
    name:'ProvincialDwards',
    data() {
        return {
            Award_publicity_module:'1',
            loading:false,
            Loading_5G:false,
            EngineeringCompetitionData:[],
            tableData_5G:[],
            InnovationCompetitionData:[],
            realkey:'',
        }
    },
    created() {
       this.winnerList();
       this.getUserInnovateNumber();
    },
    methods: {
        winnerList(){
            let uid = this.$store.state.User.user_id
            let dataobj = new FormData();
            dataobj.append("user_id", uid);
            dataobj.append("type", '省赛');
            dataobj.append("track", '学生');
            this.loading = true;
            userInfo.winnerList(dataobj).then(res=> {
                this.loading = false;
                const { data } = res;
                console.log(JSON.parse(data));
                JSON.parse(data).forEach((ele, index) => {
                    let obj = {}
                    obj.group = ele.group;
                    obj.province = ele.province;
                    obj.school = ele.school;
                    obj.prize = ele.prize;
                    //队员信息
                    ele.players.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '1'] = item[key]
                            }
                        } else if (index === 1) {
                            for (let key in item) {
                                obj[key + '2'] = item[key]
                            }
                        }
                    })
                    //教师信息
                    ele.teachers_adviser.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '3'] = item[key]
                            }
                        } else if (index === 1) {
                            for (let key in item) {
                                obj[key + '4'] = item[key]
                            }
                        } else if (index === 2) {
                            obj.tphone3 = item.phone
                            obj.tmail3 = item.mail
                        }

                    })
                    this.EngineeringCompetitionData.push(obj);
                })
            })
        },
        //5G+创新应用实践赛
        getUserInnovateNumber() {
            let uid = this.$store.state.User.user_id
            let dataobj = new FormData();
            dataobj.append("user_id", uid);
            dataobj.append("page", 1);//当前页数
            // dataobj.append("limit", 10);//一页显示多少条
            // dataobj.append("search_name", this.formInline.name);//姓名
            dataobj.append("track", '创新');
            dataobj.append("type", '省赛');
            this.Loading_5G = true;
            userInfo.winnerList(dataobj).then(res => {
                this.Loading_5G = false;
                const { count, data } = res;
                console.log(JSON.parse(data))
                // this.innovateTotal = count;
                this.tableData_5G = JSON.parse(data);
                console.log(JSON.parse(data));
            });
        },
        //失去焦点事件
        handleInputReset(row,name,type,isAllowEmpty=false) {
            let name1 = name.replace(/\s+/g,"");
            var pattern = /[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g;
            if(name1 == '' && isAllowEmpty==false) {
                return ElMessage({
                    message: '请勿输入空字符: ' + name1,
                    type: "error",
                }) 
            }
            if (pattern.test(name1)) {
                 return ElMessage({
                    message: '请勿输入特殊字符: ' + name1,
                    type: "error",
                })
            }
            this.amend(row,name,type)   
        },
        amend(row,name,type){
            let dataobj = new FormData();
            let uid = this.$store.state.User.user_id
            dataobj.append("user_id", uid);
            dataobj.append("member_id", row);
            dataobj.append("value", name);
            dataobj.append("type", type);
            userInfo.editInfo(dataobj).then(res=> {
                
                if(res.code ===0) {
                   ElMessage({
                    message:res.msg,
                    type: 'warning',
                    })
                    this.EngineeringCompetitionData=[]
                    this.winnerList();
                    return 
                }
                ElMessage({
                    message: '修改成功!',
                    type: 'success',
                })
            })
        }
        // 只能输入汉字、英文、数字
        // btKeyDown(e) {
        //     console.log(e);
        // e.target.value = e.target.value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g,"");
        // },
        //限制输入特殊字符
        // btKeyUp(value) {
        //     console.log(vaue);
        //     return value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,"");
        // }
    }
}
</script>

<style lang="scss" scoped>
.container-box {
    width: 95%;
    margin: auto;
    
    // :deep(.el-tabs__content){
    //     height: 6.8rem;
    //     overflow-y: scroll;
    // }
}
:deep(.el-input__wrapper) {
    // box-shadow: none;
}
</style>